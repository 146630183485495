import Button from "../Button/Button";

import "./Treker.css";

const Treker = ({ openModal }) => {
  return (
    <div className="treker relative z-50 row-span-1 col-span-1 max-w-[1920px] w-full mx-auto flex flex-col items-end pr-[150px] pt-[300px] pb-[1000px]">
      
      <h3 className="treker-title text-6xl text-white">ТРЕКЕР АВТО</h3>
      <p className="treker-description relative text-3xl text-white mb-10 max-w-[400px] text-end">
        <a id="Трекер" className="absolute -top-[200px]"/>
        Відстежуйте геолокацію авто за допомогою VIN-коду
      </p>

      <a
        href="https://tracking.magnumcarss.com.ua/"
        className="whywe-button  treker-button button why-button-container"
        // classesButton="whywe-button treker-button"
        // classesDiv="why-button-container treker-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>ТРЕКЕР</span>
        
      </a>
    </div>
  );
};

export default Treker;
