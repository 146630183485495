import Button from "../Button/Button";
import "./Main.css";


const Main = ({ openModal }) => {
  return (
    <main className="main row-span-1 col-span-1 relative z-50 pt-[100px] h-[100vh] max-w-[1920px] mx-auto">
      {/* <img src={mainBack} alt="main back" className="main-back-1920"/> */}
      <div className="main-container max-w-[629px] w-full h-full flex flex-col relative top-[100px] left-[150px]">
        <h1 className="main-title text-[60px] font-bold text-[rgb(24,24,24)] leading-none mb-3 dark:text-[#FBFBFB]">
          АВТО З-ЗА КОРДОНУ З ВИГОДОЮ ДО <span>40%</span>
        </h1>
        <div className="main-text text-[30px] max-w-[550px] leading-none mb-[106px] dark:text-[#E9E9E9]">
          Пройдіть короткий тест для підбору авто і скористайтесь консультацією
        </div>

        <Button
          text={"ПІДІБРАТИ"}
          classesButton={"button-main"}
          classesDiv={"button-main"}
          onClick={() => openModal()}

        />
      </div>
      
    </main>
  );
};

export default Main;
