import HookForm from "../Form/Form";

import phones from "../../assets/image/form/phons.png";


import lightBottom1920 from "../../assets/image/main-image/1920/bottom1920.png";
import darkBottom1920 from "../../assets/image/main-image/1920/dark-theme-1920-bottom.png";

import lightBottom1400 from "../../assets/image/main-image/1400/light-theme-1400-bottom.png";
import darkBottom1400 from "../../assets/image/main-image/1400/light-theme-1400-bottom.png";

import "./FeedBack.css";
import {useEffect, useState} from "react";

const FeedBack = ({setStatusThanks, darkMode}) => {
    const screenWidth = window.innerWidth;
    const [statusMessage, setStatusMessage] = useState(false);

    const selectThemeImgBottom = () => {
        const screenWidth = window.screen.width;
        if (darkMode) {
            if (screenWidth > 1530) {
                return darkBottom1920;
            } else if (screenWidth > 1390) {
                return darkBottom1400;
            }
        } else {
            if (screenWidth > 1530) {
                return lightBottom1920;
            } else if (screenWidth > 1390) {
                return lightBottom1400;
            }
        }
    };

    useEffect(() => {
    }, [statusMessage]);


    return (
        <div className="relative">
            {screenWidth > 1390 ?
                <img src={selectThemeImgBottom()} alt="main back" className="main-back-middle"/> : null}

            <div className="feedBack relative -top-[50px] z-50 max-w-[1920px] mx-auto flex justify-between px-[68px]">

                <div className="feedBack-form-container max-w-[827px]">
                    <h2 className="feedBack-form-title text-[60px] text-[#ECECEC] uppercase max-w-[700px]">
                        бажаєте отримати швидку відповідь?
                    </h2>
                    <p className="feedBack-form-description text-[30px] text-[#ffffff]">
                        Наші фахівці підберуть для вас чудові варіанти авто,враховуючи
                        побажання і бюджет
                    </p>

                    <HookForm
                        buttonStyle={"button-submit"}
                        setStatus={() => {
                            setStatusThanks()
                            setStatusMessage(true)
                        }}
                        formType="callback"
                    />
                </div>
                <img src={phones} className="feedBack-form-img pr-2" alt="phone"/>

                <div className="flex flex-col items-center relative -top-[170px] feedBack-reduce">
                    <h2 className="feedBack-reduce-title text-[60px] text-[#ECECEC] font-bold uppercase">
                        знижка <span className="text-[#FFD000]">100$</span> для військових
                    </h2>
                    <p className="feedBack-reduce-description text-[30px] text-[#ECECEC]">
                        Частину прибутку відправляємо в ЗСУ і волонтерам
                    </p>
                </div>
            </div>
        </div>
    );
};

export default FeedBack;
