import document from "../../assets/image/benefits/document.png";
import report from "../../assets/image/benefits/report.png";
import delivery from "../../assets/image/benefits/delivery.png";
import broker from "../../assets/image/benefits/broker.png";
import testing from "../../assets/image/benefits/testing.png";
import team from "../../assets/image/benefits/team.png";
import { useEffect, useState } from "react";

const benefitsData = [
  {
    title: "КОМАНДА",
    img: team,
    text: "Підбором, купівлею і доставкою займаються експерти, працюємо на всіх етапах без посередників, маємо філіали в країнах Європи та СШA",
    classes: "benefits-bottom",
  },
  {
    title: "БРОКЕР",
    img: broker,
    text: "Митні послуги кваліфікованих брокерів компанії Magnum Cars, які заощадять час і уникнуть проблем з розмитненням будь яких авто",
    classes: "benefits-bottom",
  },
  {
    title: "ПЕРЕВІРКА",
    img: testing,
    text: "Повна страхова історія автомобіля, а також тест драйв та перевірка лакофарбового покриття авто",
    classes: "benefits-bottom",
  },
  {
    title: "ДОСТАВКА",
    img: delivery,
    text: "Найдоступніші ціни з доставки авто, власні автовози, працюємо з усіма портами Європи",
    classes: "benefits-top",
  },
  {
    title: "ЗВІТ",
    img: report,
    text: "Відео та фото супровід від моменту купівлі до моменту передачі автомобіля клієнту",
    classes: "benefits-top",
  },
  {
    title: "ДОГОВІР",
    img: document,
    text: "Підписуємо договір, усі платежі на офіційні банківські рахунки та фіксуємо вартість авто ще до викупу з аукціону",
    classes: "benefits-top",
  },
];

const BenefitsList = () => {
  const [activeCard, setActiveCard] = useState(0);

  useEffect(() => {
      setTimeout(() => {
        setActiveCard((state) =>
        state === null
            ? null
            : activeCard >= benefitsData.length - 1
            ? 0
            : activeCard + 1
        );
      }, 5000);
  }, [activeCard]);

  const renderItem = ({ title, img, text }, i) => {
    return (
      <li
        key={title}
        className={"benefits-item"}
        onMouseOver={() => {
          setActiveCard(null);
        }}
        onMouseLeave={() => {
            setActiveCard(0);
        }}
      >
        <div
          className={`benefits-item-front ${
            i === activeCard
              ? "opacity-0 transition-all duration-500 "
              : "opacity-100 transition-all duration-500 "
          }`}
        >
          <div className="benefits-item-front-container">
            <img src={img} alt={title} className="" />
            <span className="benefits-item-front-span">{title}</span>
          </div>
        </div>

        <div
          className={`benefits-item-back ${
            i === activeCard
              ? "opacity-100 transition-all duration-500"
              : "opacity-0"
          }`}
        >
          <div className="benefits-item-back-container">
            <span className="benefits-item-back-title">{title}</span>
            <p className="benefits-item-back-text">{text}</p>
          </div>
        </div>
      </li>
    );
  };

  return (
    <ul className="benefits-list flex flex-wrap justify-center max-w-[1659px] w-full">
      {benefitsData.map((item, i) => renderItem(item, i))}
    </ul>
  );
};

export default BenefitsList;
