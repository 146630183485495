import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import one from "../../assets/image/share/1.png";
import two from "../../assets/image/share/2.png";
import three from "../../assets/image/share/3.png";
import four from "../../assets/image/share/4.png";
import five from "../../assets/image/share/5.png";
import { useState } from "react";

const data = [
  `Copart - це один з найбільших у світі інтернет-аукціонів автотранспорту,
  що спеціалізується на продажу авто з пошкодженнями. Особливість
  Copart у тому, що вони продають автомобілі в стані "як є" (as is),
  тобто покупець має можливість оглянути авто перед купівлею`,

  `Американський аукціон автомобілів спеціалізується на продажі б/у
  автомобілів, включаючи легкові автомобілі, вантажівки, мотоцикли
  та інші типи транспорту. Особливість цього аукціону полягає у
  великому виборі різних марок і моделей автомобілів.`,

  `Adesa Auction є великим аукціоном автомобілів, який працює з
  дилерами та громадськістю. Особливість Adesa полягає у високому
  рівні обслуговування клієнтів та різноманітті доступних послуг, таких
  як перевірка стану авто, фінансування, доставка і технічні інспекці`,
  `Manheim є одним з найбільших аукціонів автомобілів у США та світі,
  який працює як для дилерів, так і для громадськості. Особливість
  Manheim у широкому асортименті авто, включаючи нові моделі, б/у
  авто, лімузини, грузовики та інше.`,

  `IAAI спеціалізується на продажі авто, що мають страхові збитки або
  були причетні до страхових випадків. Особливість цього аукціону
  полягає у тому, що багато автомобілів на аукціоні мають історію
  страхових виплат, і це може вплинути на їхню вартість.`,
];

const ShereSlider = () => {
  const [activeText, setActiveText] = useState(0);
  const settings = {
    centerMode: true,
    arrows: true,
    infinite: true,
    adaptiveHeight: true,
    slidesToShow: 3,
    // autoplay: true,
    centerPadding: "0px",
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          // arrows: false,
          centerMode: true,
          // centerPadding: "250px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          // arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <div className="">
      <Slider
        {...settings}
        beforeChange={(currentSlide, nextSlide) => {
          let allSlides = document.querySelectorAll(".slick-slide");
          let nextIndex = currentSlide + 1; // assume moving right
          setActiveText(nextSlide);
          if (
            currentSlide - 1 === nextSlide ||
            (nextSlide + 1 === allSlides.length && currentSlide < nextSlide)
          ) {
            nextIndex = currentSlide - 1; // nope, moving left
          }
          document
            .querySelector(`[data-index="${nextIndex + 1}"]`)
            .classList.add("slick-current-end");

          console.log(
            document.querySelector(`[data-index="${nextIndex + 1}"]`)
          );

          // console.log(nextIndex - 1);
          // console.log(document.querySelector(`.slick-active-end`));
          // document
          //   .querySelector(`[data-index="${currentSlide + 1}"]`)
          //   .classList.add("slick-active");

          // document
          //   .querySelector(`[data-index="${currentSlide - 1}"]`)
          //   .classList.add("slick-active");
        }}
        afterChange={(nextSlide) => {
          // document
          //   .querySelector(".slick-slide")
          //   .classList.remove("slick-current-end");
          // document
          //   .querySelector(`[data-index="${nextSlide}"]`)
          //   .classList.add("slick-current-end");
          // document
          //   .querySelector(`[data-index="${nextSlide + 1}"]`)
          //   .classList.add("slick-current-end");
          // document
          //   .querySelector(`[data-index="${nextSlide - 1}"]`)
          //   .classList.add("slick-current-end");
        }}
      >
        <img src={one} alt="copart" className="w-full" />
        <img src={two} alt="copart" className="w-full" />
        <img src={three} alt="copart" className="w-full" />
        <img src={four} alt="copart" className="w-full" />
        <img src={five} alt="copart" className="w-full" />
      </Slider>
      <p className="share-text text-white h-[160px]">
        {data[activeText]}
      </p>
    </div>
  );
};

export default ShereSlider;
