import CarService from "../CarService/CarService";
import './OurServices.css';

const OurServices = () => {
  return (
    <div className="service relative z-50 row-span-1 col-span-1 max-w-[1920px] w-full mx-auto">
      <a id="Автосервіс" className="absolute -top-[100px]"/>
      <div className="service-container flex flex-col items-center">
        <h2 className="service-title text-6xl text-white uppercase mb-3">
          власне сто та детейлінг студія
        </h2>
        <p className="service-description text-3xl text-white text-center max-w-[1000px] w-full ">
          Від технічного обслуговування до професійного детейлінгу наші фахівці
          забезпечать вашому автомобілю бездоганний вигляд та технічну
          справність.
        </p>

        <CarService />

        <div className="service-reduce flex flex-col items-center relative -top-[170px]">
          <h2 className="service-reduce-title text-[60px] text-[#ECECEC] font-bold uppercase">
            знижка <span className="text-[#FFD000]">100$</span> для військових
          </h2>
          <p className="service-reduce-description text-[30px] text-[#ECECEC]">
            Частину прибутку відправляємо в ЗСУ і волонтерам
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
