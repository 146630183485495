import buttonBack from "../../assets/image/big-modal/button-back.png";
import usa from "../../assets/image/big-modal/usa.png";
import europe from "../../assets/image/big-modal/europe.png";
import canada from "../../assets/image/big-modal/canade.png";
import china from "../../assets/image/big-modal/china.png";
import "./SlideThree.css";
import { selectedItem } from "./SlideOne";

const slideOneData = [
  {
    src: usa,
    alt: "Америка",
    short: "США",
    classHover: "big-modal-slide-three-item-hover",
    classActive: "big-modal-slide-three-item-active-active",
  },
  {
    src: europe,
    alt: "Європа",
    classHover: "big-modal-slide-three-item-hover",
    classActive: "big-modal-slide-three-item-active-active",
  },
  {
    src: canada,
    alt: "Канада",
    classHover: "big-modal-slide-three-item-hover",
    classActive: "big-modal-slide-three-item-active-active",
  },
  {
    src: china,
    alt: "Китай",
    classHover: "big-modal-slide-three-item-hover",
    classActive: "big-modal-slide-three-item-active-active",
  },
];

export const SlideThree = ({ status, setThird, prevSlide, nextSlide }) => {
  const screenWidth = window.innerWidth;
  return (
    <div className="big-modal-slide-container">
      <h2 className="big-modal-slide-title three">
        АВТОМОБІЛЬ БУДЕ ДОСТАВЛЯТИСЬ З:{" "}
      </h2>
      <p className="big-modal-slide-text">для підбору авто(3/5)</p>
      <button
        className="big-modal-slide-button-back"
        onClick={() => prevSlide()}
        disabled={false}
      >
        <img src={buttonBack} alt="button back" />
      </button>

      <ul className="big-modal-slide-three-list ">
        {slideOneData.map((item) => {
          const { src, alt, classHover, classActive, short } = item;
          return (
            <li
              className="big-modal-slide-three-item"
              data-title={alt}
              onClick={(e) => {
                selectedItem(e, setThird)
              }}
              key={src}
            >
              {screenWidth > 1000 ? <div className={classHover}></div> : ""}
              <img src={src} alt={alt} className="big-modal-slide-three-img" />

              <div
                className={`big-modal-slide-three-item-active ${
                  Array.isArray(status) && status.includes(alt)
                    ? classActive
                    : ""
                }`}
              ></div>

              <span className="big-modal-slide-three-item-title">
                {screenWidth > 1000 ? alt : short !== undefined ? short : alt}
              </span>
            </li>
          );
        })}
      </ul>
      <div className="slide-buttons flex justify-between">
        <button
          className={`big-modal-button ${
            Array.isArray(status) && status[0] === "Не важливо"
              ? "big-modal-button-active"
              : ""
          } `}
          onClick={(e) => selectedItem(e, setThird)}
          data-title={"Не важливо"}
        >
          Поки не визначився / не важливо
        </button>
        <button
          disabled={status.length === 0 ? true : false}
          className={`big-modal-button-next `}
          onClick={() => nextSlide()}
        >
          Далі
        </button>
      </div>
    </div>
  );
};
