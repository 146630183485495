import { useEffect, useState } from "react";
import "./App.css";

// import light from "../../assets/image/main-image/light-theme.png";
// import dark from "../../assets/image/main-image/dark-theme.png";
// import light1400 from "../../assets/image/main-image/light-theme-1400.png";
// import light768 from "../../assets/image/main-image/light-theme-768.png";
// import light450 from "../../assets/image/main-image/light-theme-450.png";
// import dark450 from "../../assets/image/main-image/dark-theme-450.png";

import lightTop1920 from "../../assets/image/main-image/1920/top1920.png";
import darkTop1920 from "../../assets/image/main-image/1920/dark-theme-1920-top.png";
import lightMiddle1920 from "../../assets/image/main-image/1920/middle1920.png";
import darkMiddle1920 from "../../assets/image/main-image/1920/dark-theme-1920-middle.png";
import lightBottom1920 from "../../assets/image/main-image/1920/bottom1920.png";
import darkBottom1920 from "../../assets/image/main-image/1920/dark-theme-1920-bottom.png";
import lightTop1400 from "../../assets/image/main-image/1400/light-theme-1400-top.png";
import darkTop1400 from "../../assets/image/main-image/1400/dark-theme-1400-top.png";
import lightMiddle1400 from "../../assets/image/main-image/1400/light-theme-1400-middle.png";
import darkMiddle1400 from "../../assets/image/main-image/1400/dark-theme-1400-middle.png";
import lightBottom1400 from "../../assets/image/main-image/1400/light-theme-1400-bottom.png";
import darkBottom1400 from "../../assets/image/main-image/1400/light-theme-1400-bottom.png";
import lightTop768 from "../../assets/image/main-image/768/light2-theme-768-top.png";
// import darkTop768 from "../../assets/image/main-image/1400/dark-theme-1400-top.png";
import lightMiddle768 from "../../assets/image/main-image/768/light2-theme-768-bottom.png";
// import darkMiddle768 from "../../assets/image/main-image/768/";

import lightTop450 from "../../assets/image/main-image/450/light-theme-450-top.png";
import darkTop450 from "../../assets/image/main-image/450/dark-theme-top-450.png";
import lightMiddle450 from "../../assets/image/main-image/450/light-theme-450-bottom.png";
import darkMiddle450 from "../../assets/image/main-image/450/dark-theme-bottom-450.png";

import mainBack from "../../assets/image/main-image/1920/top1920.png";

import Header from "../Header/Header";
import Main from "../Main/Main";
import Review from "../Review/Review";
import WhyWe from "../WhyWe/WhyWe";
import Treker from "../Treker/Treker";
import ShareWorkWith from "../ShareWorkWith/ShareWorkWith";
import OurServices from "../OurServices/OurServices";
import FeedBack from "../FeedBack/FeedBack";
import Footer from "../Footer/Footer";
import SmallModal from "../SmallModal/SmallModal";
import BigModal from "../BigModal/BigModal";
import Preloader from "../Preloader/Preloader";
import { Helmet } from "react-helmet";

function App() {
  const screenWidth = window.screen.width;

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theam")
      ? localStorage.getItem("theam").toLowerCase() === "true"
      : false
  );
  const [smallShow, setSmallShow] = useState(false);
  const [bigShow, setBigShow] = useState(false);
  const [preloader, setPreloader] = useState(true);
  const [statusThanks, setStatusThanks] = useState(false);

  useEffect(() => {
    localStorage.setItem("theam", darkMode);

    // window.location.reload()
  }, [darkMode]);

  useEffect(() => {
    if (smallShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [smallShow]);

  useEffect(() => {
    if (bigShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [bigShow]);

  // const selectThemeImg = () => {
  //   const screenWidth = window.screen.width;
  //   if (darkMode) {
  //     if (screenWidth > 1000) {
  //       return dark;
  //     } else {
  //       return dark450;
  //     }
  //   } else {
  //     if (screenWidth > 1530) {
  //       return light;
  //     } else if (screenWidth > 1150) {
  //       return light1400;
  //     } else if (screenWidth > 700) {
  //       return light768;
  //     } else {
  //       return light450;
  //     }
  //   }
  // };

  const selectThemeImgTop = () => {
    const screenWidth = window.screen.width;
    if (darkMode) {
      if (screenWidth > 1530) {
        return darkTop1920;
      } else if (screenWidth > 1150) {
        return darkTop1400;
      } else if (screenWidth > 700) {
        return darkTop450;
      } else {
        return darkTop450;
      }
    } else {
      if (screenWidth > 1530) {
        return lightTop1920;
      } else if (screenWidth > 1150) {
        return lightTop1400;
      } else if (screenWidth > 700) {
        return lightTop768;
      } else {
        return lightTop450;
      }
    }
  };

  const selectThemeImgMiddle = () => {
    const screenWidth = window.screen.width;
    if (darkMode) {
      if (screenWidth > 1530) {
        return darkMiddle1920;
      } else if (screenWidth > 1150) {
        return darkMiddle1400;
      } else if (screenWidth > 700) {
        return darkMiddle450;
      } else {
        return darkMiddle450;
      }
    } else {
      if (screenWidth > 1530) {
        return lightMiddle1920;
      } else if (screenWidth > 1150) {
        return lightMiddle1400;
      } else if (screenWidth > 700) {
        return lightMiddle768;
      } else {
        return lightMiddle450;
      }
    }
  };

  const selectThemeImgBottom = () => {
    const screenWidth = window.screen.width;
    if (darkMode) {
      if (screenWidth > 1530) {
        return darkBottom1920;
      } else if (screenWidth > 1150) {
        return darkBottom1400;
      } else if (screenWidth > 700) {
        return;
      } else {
        return;
      }
    } else {
      if (screenWidth > 1530) {
        return lightBottom1920;
      } else if (screenWidth > 1150) {
        return lightBottom1400;
      } else if (screenWidth > 700) {
        return lightMiddle768;
      } else {
        return lightMiddle450;
      }
    }
  };

  // const setHeghtSite = () => {
  //   const screenWidth = window.innerWidth;

  //   if (screenWidth > 1600) {
  //     return "h-[9720px]";
  //   } else if (screenWidth > 1249) {
  //     return "h-[9051px]";
  //   } else if (screenWidth > 1150) {
  //     return "h-[8551px]";
  //   } else if (screenWidth > 700) {
  //     return "h-[7069px]";
  //   } else {
  //     return "h-[5410px]";
  //   }
  // };

  setTimeout(() => setPreloader(false), 2500);

  return (
    <div className={`${darkMode ? "dark" : ""} w-full bg-black `}>
      <Helmet>
        <script>
          {`
            


          `}
        </script>
      </Helmet>
      {preloader ? <Preloader /> : null}
      <div className="relative z-50  h-full  w-full mx-auto ">
        <Header setDarkMode={setDarkMode} darkMode={darkMode} />

        {smallShow ? <SmallModal close={() => setSmallShow(false)} /> : null}
        {statusThanks ? (
          <div className="small-modal z-50 ">
            <div className="small-modal-container">
              <div
                className="small-modal-close"
                onClick={() => setStatusThanks(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="45"
                  viewBox="0 0 45 45"
                  fill="none"
                >
                  <rect
                    x="33.4551"
                    y="9"
                    width="4.64572"
                    height="34.5848"
                    transform="rotate(45 33.4551 9)"
                    fill="#D9D9D9"
                  />
                  <rect
                    x="9"
                    y="12.2852"
                    width="4.64572"
                    height="34.5848"
                    transform="rotate(-45 9 12.2852)"
                    fill="#D9D9D9"
                  />
                </svg>
              </div>
              <h2 className="small-modal-sucses-title">
                ваша заявка успішно відправлена!
              </h2>
              <p className="small-modal-text-top ">
                Дякуємо! Наш менеджер зв'яжеться з Вами найближчим часом!
              </p>

              <a
                href="https://www.instagram.com/magnumcarss/?igsh=MXd5ZjY2M2ttODhtcQ%3D%3D"
                className="small-modal-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                НАШ ІНСТАГРАМ
              </a>

              <p className="small-modal-text-bottom">
                РЕКОМЕНДУЄМО ОЗНАЙОМИТИСЬ З НАШИМ ІНСТАГРАМОМ
              </p>
            </div>
          </div>
        ) : null}
        {bigShow ? <BigModal close={() => setBigShow(false)} /> : null}

        <div className="grid grid-cols-1 grid-rows-1 justify-center h-full z-10 relative mx-auto max-w-[1920px]">
          {/* <img
            src={selectThemeImg()}
            className={`main-image w-full h-full  row-span-1 col-span-1 z-10	absolute ${
              window.screen.width > 1150 ? "object-cover" : "object-cover"
            }`}
            alt="main"
          /> */}

          <div className="relative z-50 overflow-hidden">
            <div className="relative " style={screenWidth > 700 ? {width: '101%'} : {width: '100%'}}>
              <img
                src={selectThemeImgTop()}
                alt="main back"
                style={
                  screenWidth > 1150
                    ? {}
                    : darkMode
                    ? { top: "80px" }
                    : { top: "50px" }
                }
                className="main-back-1920"
              />
              <Main openModal={() => setBigShow(true)} />

              <Review />
            </div>

            <WhyWe openModal={() => setSmallShow(true)} />
            <div className="middle-conteiner relative">
              <img
                src={selectThemeImgMiddle()}
                alt="main back"
                className="main-back-middle"
              />

              <Treker openModal={() => setSmallShow(true)} />
              <ShareWorkWith />

              <OurServices />
            </div>

            <FeedBack
              setStatusThanks={() => setStatusThanks(true)}
              darkMode={darkMode}
            />
          </div>
        </div>
        <a id="Контакти" />
        <Footer />
      </div>
    </div>
  );
}

export default App;
