import "./ToggleTheam.css";

const ToggleTheam = ({ setDarkMode, darkMode }) => {
  return (
    <>
      <label>
        <input
          onChange={(e) => setDarkMode((state) => !state)}
          checked={darkMode}
          className="toggle-checkbox"
          type="checkbox"
        ></input>
        <div className="toggle-slot">
          <div className="sun-icon-wrapper">
            <div
              className="iconify sun-icon"
              data-icon="feather-sun"
              data-inline="false"
            ></div>
          </div>
          <div className="toggle-button"></div>
          <div className="moon-icon-wrapper">
            <div
              className="iconify moon-icon"
              data-icon="feather-moon"
              data-inline="false"
            ></div>
          </div>
        </div>
      </label>
    </>
  );
};

export default ToggleTheam;
