import SliderSlick from "../Slider/Slider";

import "./Review.css";

const dataReview = [
  {
    // videoUrl: "https://youtube.com/shorts/RTQ6hKEpLjA?feature=share",
    videoUrl: "https://www.youtube.com/watch?v=RTQ6hKEpLjA",
    car: "Audi Q5",
    capacity: "2.0 бензин",
    years: "2021",
    transmission: "АКПП",
  },
  {
    videoUrl: "https://www.youtube.com/watch?v=l_X3bFSGIpk",
    car: "Tesla Model 3",
    capacity: "50кВ/г батерея",
    years: "2020",
    transmission: "АКПП",
  },
  {
    videoUrl: "https://www.youtube.com/watch?v=6A6GqQ4Y76E",
    car: "Ford Ecosport",
    capacity: "2.0 бензин",
    years: "2020",
    transmission: "АКПП",
  },
  {
    videoUrl: "https://www.youtube.com/watch?v=MFmUP4TWAPE",
    car: "FORD FOCUS",
    capacity: "1.6 бензин",
    years: "2008",
    transmission: "МКПП",
  },
  {
    videoUrl: "https://www.youtube.com/watch?v=0aKZVCrY2AA",
    car: "Renault Megane",
    capacity: "1.9 дизель",
    years: "2011",
    transmission: "МКПП",
  },
  {
    videoUrl: "https://www.youtube.com/watch?v=xW5n45fUo14",
    car: "OPEL INSIGNIA",
    capacity: "2.0 дизель",
    years: "2011",
    transmission: "АКПП",
  },
  {
    videoUrl: "https://www.youtube.com/watch?v=384ngAxtj8Y",
    car: "Skoda Octavia",
    capacity: "2.0 дизель",
    years: "2006",
    transmission: "МКПП",
  },
];

const Review = () => {
  return (
    <div className="review relative z-50 row-span-1 col-span-1 max-w-[1920px] mb-10 w-full mx-auto overflow-hidden">
      <a id="Відгуки" className="absolute -top-[120px]"/>
      <div className="review-text-wrapper relative left-[150px]">
        
        <h2 className="review-title text-6xl text-[#ECECEC] mb-2 ">
          ВІДГУКИ КЛІЄНТІВ
        </h2>
        <p className="review-description text-3xl text-white mb-[50px] max-w-[700px] ">
          Які переконалися в високому рівні нашого сервісу та вже насолоджуються
          своїми автомобілями.
        </p>
      </div>
      <div className="slide-reviews">
        <SliderSlick data={dataReview} />
      </div>
    </div>
  );
};

export default Review;
