import { useState } from "react";
import HookForm from "../Form/Form";

import buttonBack from "../../assets/image/big-modal/button-back.png";
import "./SlideFive.css";

export const SlideFive = ({ close, data, prevSlide }) => {
  const [statusMessage, setStatusMessage] = useState(true);

  const modalClose = () => {
    setStatusMessage(true);
    close();
  };
  return (
    <div className="big-modal-slide-container five">
      {statusMessage ? (
        <>
          <h2 className="big-modal-slide-title">ЗАЛИШИВСЯ ОСТАННІЙ КРОК</h2>
          <p className="big-modal-slide-text">
            Вкажіть Ваші контактні дані для отримання результату підбору
          </p>
          <button
            className="big-modal-slide-button-back"
            onClick={() => prevSlide()}
            disabled={false}
          >
            <img src={buttonBack} alt="button back" />
          </button>
        </>
      ) : (
        <>
          <div className="big-modal-form-close" onClick={() => modalClose()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 80 80"
              fill="none"
            >
              <rect
                x="0.00012207"
                y="7.46484"
                width="10.5595"
                height="102.578"
                transform="rotate(-45 0.00012207 7.46484)"
                fill="#D9D9D9"
              />
              <rect
                x="72.5333"
                width="10.5595"
                height="102.578"
                transform="rotate(45 72.5333 0)"
                fill="#D9D9D9"
              />
            </svg>
          </div>
        </>
      )}
      {statusMessage ? (
        <div className="big-modal-form-container">
          <>
            <div className="big-modal-form-wrapper">
              <HookForm
                buttonStyle={"big-modal-form-button"}
                setStatus={() => setStatusMessage(false)}
                classes={"newInputStyle"}
                data={data}
                formType="quiz"
              />
            </div>
          </>
        </div>
      ) : (
        <div className="big-modal-form-sucses-wrapper">
          <h2 className="big-modal-form-sucses-title">
            ваша заявка успішно відправлена!
          </h2>
          <p className="big-modal-form-text-top ">
            Дякуємо! Наш менеджер зв'яжеться з Вами найближчим часом!
          </p>

          <a
            href="https://www.instagram.com/magnumcarss/?igsh=MXd5ZjY2M2ttODhtcQ%3D%3D"
            className="big-modal-form-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            НАШ ІНСТАГРАМ
          </a>

          <p className="big-modal-form-text-bottom">
            РЕКОМЕНДУЄМО ОЗНАЙОМИТИСЬ З НАШИМ ІНСТАГРАМОМ
          </p>
        </div>
      )}
    </div>
  );
};
