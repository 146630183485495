import ShereSlider from "../ShareSlider/ShareSlider";

import './ShareWorkWith.css';

const ShareWorkWith = () => {
  return (
    <div className="share relative z-50 row-span-1 col-span-1 max-w-[1920px]  w-full mx-auto mb-[100px]">
      <h2 className="share-title  text-white uppercase pl-[152px]">
        аукціони з якими ми працюємо
      </h2>
      <p className="share-description  text-white pl-[152px] max-w-[1089px] mb-4">
        Ми співпрацюємо лише з перевіреними та надійними аукціонами в США,
        Європі та Канаді, забезпечуючи вам доступ до широкого спектру
        автомобілів на будь-який смак і бюджет
      </p>

      <div className=" share-slider">
        <ShereSlider />
      </div>
      <div className="pl-[150px]"></div>
    </div>
  );
};

export default ShareWorkWith;
