import React, { createContext, useContext, useState, useEffect } from 'react';
import queryString from 'query-string';

const UtmContext = createContext();

export const UtmProvider = ({ children }) => {
    const [utmData, setUtmData] = useState({});

    useEffect(() => {

        const parseAndSaveUtmData = () => {
            const queryParams = window.location.search;
            const parsedParams = queryString.parse(queryParams);
            setUtmData(parsedParams);

            for (const [key, value] of Object.entries(parsedParams)) {
                localStorage.setItem(key, value);
            }
        };
        parseAndSaveUtmData();
    }, []);

    return (
        <UtmContext.Provider value={{ utmData, setUtmData }}>
            {children}
        </UtmContext.Provider>
    );
};

export const useUtm = () => useContext(UtmContext);