import buttonBack from "../../assets/image/big-modal/button-back.png";
import rich from "../../assets/image/big-modal/rich.png";
import "./SlideFour.css";
import { selectedItem } from "./SlideOne";

const slideOneData = [
  {
    alt: "4 000-6 000$",
    classHover: "big-modal-slide-one-item-hover-top",
    classActive: "big-modal-slide-one-item-active-top",
  },
  {
    alt: "6 000-9 000$",
    classHover: "big-modal-slide-one-item-hover-top",
    classActive: "big-modal-slide-one-item-active-top",
  },
  {
    alt: "9 000-12 000$",
    classHover: "big-modal-slide-one-item-hover-top",
    classActive: "big-modal-slide-one-item-active-top",
  },
  {
    alt: "12 000-15 000$",
    classHover: "big-modal-slide-one-item-hover-bottom",
    classActive: "big-modal-slide-one-item-active-bottom",
  },
  {
    alt: "15 000-20 000$",
    classHover: "big-modal-slide-one-item-hover-bottom",
    classActive: "big-modal-slide-one-item-active-bottom",
  },
  {
    alt: "більше 30 000$",
    src: rich,
    classHover: "big-modal-slide-one-item-hover-bottom",
    classActive: "big-modal-slide-one-item-active-bottom",
  },
];

export const SlideFour = ({ status, setFourth, prevSlide, nextSlide }) => {
  const screenWidth = window.innerWidth;
  return (
    <div className="big-modal-slide-container four">
      <h2 className="big-modal-slide-title">НА ЯКИЙ БЮДЖЕТ ВИ РОЗРАХОВУЄТЕ?</h2>
      <p className="big-modal-slide-text">для підбору авто(4/5)</p>
      <button
        className="big-modal-slide-button-back"
        onClick={() => prevSlide()}
        disabled={false}
      >
        <img src={buttonBack} alt="button back" />
      </button>

      <ul className="big-modal-slide-one-list four">
        {slideOneData.map((item, i) => {
          const { src, alt, classHover, classActive } = item;

          return (
            <li
              className="big-modal-slide-one-item big-modal-slide-four-item"
              data-title={alt}
              onClick={(e) => {
                selectedItem(e, setFourth)
                
              }}
              key={alt}
            >
              {screenWidth > 1000 ? <div className={classHover}></div> : ""}
              <span className="big-modal-slide-one-item-price" data-price={alt}>
                {alt}
              </span>

              {i === 5 ? (
                Array.isArray(status) && status.includes(alt) ? (
                  <img
                    src={src}
                    alt={alt}
                    className="big-modal-slide-one-img-active"
                  />
                ) : null
              ) : Array.isArray(status) && status.includes(alt) ? (
                <div className={classActive}></div>
              ) : null}
              {}
            </li>
          );
        })}
      </ul>
      <div className="slide-buttons flex justify-between">
        <button
          className={`big-modal-button ${
            Array.isArray(status) && status[0] === "Не важливо"
              ? "big-modal-button-active"
              : ""
          } `}
          onClick={(e) => selectedItem(e, setFourth)}
          data-title={"Не важливо"}
        >
          Поки не визначився / не важливо
        </button>
        <button
          disabled={status.length === 0 ? true : false}
          className={`big-modal-button-next `}
          onClick={() => nextSlide()}
        >
          Далі
        </button>
      </div>
    </div>
  );
};
