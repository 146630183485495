import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Button,
} from "@chakra-ui/react";

import SendFormService from "../../service/services";

import "./Form.css";
import {Helmet} from "react-helmet";

export default function HookForm({
                                     formStyle,
                                     labelStyle,
                                     label,
                                     buttonStyle,
                                     classes,
                                     setStatus,
                                     data,
                                    formType = 'quiz'
                                 }) {
    const {
        handleSubmit,
        register,
        setValue,
        formState: {errors, isSubmitting},
    } = useForm();
    const [formSubmitted, setFormSubmitted] = useState(false);

    const {sendFormToRemoteAction} = SendFormService();

    const onSubmit = async (values) => {
        setValue("name", values.name.trim());

        const sendData = {
            name: values.name,
            phone: values.phone,
        }

        if (data !== undefined) {
            if (data.body !== undefined) {
                sendData.body = data.body.join(', ')
            }

            if (data.typeOil !== undefined) {
                sendData.typeOil = data.typeOil.join(', ')
            }

            if (data.country !== undefined) {
                sendData.country = data.country.join(', ')
            }

            if (data.price !== undefined) {
                sendData.price = data.price.join(', ')
            }
        }
        sendData.action = formType

        setFormSubmitted(true);

        try {
            const request = await sendFormToRemoteAction(sendData);
            console.log(await request);
            setValue("name", "");
            setValue("phone", "");
            setStatus();
        } catch (e) {
            console.log(e);
        }
    };

    // const handlePhoneChange = (e) => {
    //     const userInput = e.target.value;
    //     if (!userInput.startsWith("+380")) {
    //         setValue("phone", "+380" + userInput);
    //     }
    // };

    const valueTrim = (e) => {
        setValue(e.target.id, e.target.value.trim());
    };


    return (
        <>
            {
                formSubmitted ? <Helmet>
                        <script>
                            {`
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '535678358113442');
              fbq('track', 'Lead');
              console.log('track lead for 535678358113442')
            `}
                        </script>

                        <script>
                            {`
               !function(f,b,e,v,n,t,s)
               {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
               n.callMethod.apply(n,arguments):n.queue.push(arguments)};
               if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
               n.queue=[];t=b.createElement(e);t.async=!0;
               t.src=v;s=b.getElementsByTagName(e)[0];
               s.parentNode.insertBefore(t,s)}(window, document,'script',
               'https://connect.facebook.net/en_US/fbevents.js');
               fbq('init', '355939530725536');
               fbq('track', 'Lead');
               console.log('track lead for 355939530725536')
            `}
                        </script>


                    </Helmet>
                    :
                    ''
            }
            <form
                onSubmit={handleSubmit(onSubmit)}
                onChange={(e) => valueTrim(e)}
                style={formStyle}
            >
                <FormLabel
                    fontFamily=""
                    style={labelStyle}
                    fontWeight={900}
                    fontSize="36px"
                >
                    {label}
                </FormLabel>

                <FormControl
                    style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                    isInvalid={errors.name}
                >
                    <Input
                        // mb={"30px"}
                        border={"none"}
                        borderRadius={0}
                        focusBorderColor="rgba(0, 0, 0, 0.38)"
                        className={`formInputs inputOneColor ${classes}`}
                        id="name"
                        placeholder={`Ім'я`}
                        {...register("name", {
                            trim: true,
                            required: "Поле імені обов'язкове",
                        })}
                    />
                    <FormErrorMessage
                        fontSize="16px"
                        fontFamily="myBody"
                        fontWeight={"400"}
                        style={{position: "absolute", bottom: "8px"}}
                    >
                        {errors.name && errors.name.message}
                    </FormErrorMessage>
                </FormControl>
                {/* background: linear-gradient(90deg, rgba(156, 156, 156, 0.41) 0%, #414141 100%);

box-shadow: -3px 4px 7.2px 1px rgba(0, 0, 0, 0.38); */}
                <FormControl
                    isInvalid={errors.phone}
                    style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Input
                        width={"100%"}
                        border={"none"}
                        borderRadius={0}
                        focusBorderColor="rgba(0, 0, 0, 0.38)"
                        className={`formInputs inputTwoColor ${classes}`}
                        borderColor={errors.phone && errors.phone.message ? "redC.100" : ""}
                        id="phone"

                        onKeyDown={(e) => {
                            const key = e.key;
                            // Перевірка, чи натиснута клавіша Backspace та чи видаляється номер телефону
                            if ((key === 'Backspace' || key === 'Delete') && e.target.value.length <= 4) {
                                e.preventDefault(); // Заборона видалення коду країни
                            }


                        }}

                        onKeyPress={(e) => {
                            if (e.target.value.length >= 13) {
                                e.preventDefault();
                            }

                            if (isNaN(Number(e.key))) {
                                e.preventDefault();
                            }
                        }}
                        placeholder="Номер телефону"
                        defaultValue="+380"
                        {...register("phone", {
                            required: "Це поле обов'язкове",

                            pattern: {
                                value: /^(\+?3?8?)?0\d{9}$/,
                                message: "Формат телефону +380999999999",
                            },
                        })}
                    />
                    <FormErrorMessage
                        fontSize="16px"
                        fontFamily="myBody"
                        fontWeight={"400"}
                        style={{position: "absolute", bottom: "8px"}}
                    >
                        {errors.phone && errors.phone.message}
                    </FormErrorMessage>
                </FormControl>

                <Button
                    mt={6}
                    width={"100%"}
                    // colorScheme="teal"
                    borderRadius={0}
                    isLoading={isSubmitting}
                    type="submit"
                    className={buttonStyle}
                    // onClick={}
                >
                    Надіслати
                </Button>
            </form>
        </>
    );
}
