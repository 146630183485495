import logo from '../../assets/image/logo.png';
import Spinner from '../spiner/spiner';
import './Preloader.css'

const Preloader = () => {
  return (
    <div className='preloader'>
      <div className="preloader-container">
        <img src={logo} alt="logo" className='preloader-logo'/>
        <div className='preloader-spinner'>
          <Spinner/>
        </div>
      </div>
    </div>
  )
}

export default Preloader;