
import Slider from "react-slick";

import "./BigModal.css";
import { useRef, useState } from "react";

import { SlideOne } from "../BigModalSlides/SlideOne";
import { SlideTwo } from "../BigModalSlides/SlideTwo";
import { SlideThree } from "../BigModalSlides/SlideThree";
import { SlideFour } from "../BigModalSlides/SlideFour";
import { SlideFive } from "../BigModalSlides/SlideFive";

const BigModal = ({ close }) => {
  const [first, setFirst] = useState([]);
  const [thecond, setThecond] = useState([]);
  const [third, setThird] = useState([]);
  const [fourth, setFourth] = useState([]);

  const sliderRef = useRef(null);

  const settings = {
    // centerMode: true,
    arrows: false,
    infinite: false,
    adaptiveHeight: true,
    slidesToShow: 1,
    dots: false,
    swipe: false
    // autoplay: true,
    // centerPadding: "150px",
    // variableWidth: true,
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div className="big-modal z-50 ">
      <div className="big-modal-container">
        {/* <div className="big-modal-track"> */}
        <Slider ref={sliderRef} {...settings}>
          <SlideOne
            prevSlide={prevSlide}
            nextSlide={nextSlide}
            status={first}
            setFirst={setFirst}
            close={close}
          />
          <SlideTwo
            prevSlide={prevSlide}
            nextSlide={nextSlide}
            status={thecond}
            setThecond={setThecond}
          />
          <SlideThree
            prevSlide={prevSlide}
            nextSlide={nextSlide}
            status={third}
            setThird={setThird}
          />
          <SlideFour
            prevSlide={prevSlide}
            nextSlide={nextSlide}
            status={fourth}
            setFourth={setFourth}
          />
          <SlideFive
            prevSlide={prevSlide}
            nextSlide={nextSlide}
            status={fourth}
            setFourth={setFourth}
            close={close}
            data={{
              body: first,
              typeOil: thecond,
              country: third,
              price: fourth,
            }}
          />
        </Slider>
        {/* </div> */}
      </div>
    </div>
  );
};

export default BigModal;
